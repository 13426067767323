import React from 'react'
import { useStaticQuery, graphql, PageProps } from 'gatsby'
import { SeoProps } from 'components/shared/SEO'

// CSS
import 'styles/all.css'
import 'styles/preview.css'

// Components
import Page from 'templates/page'
import Post from 'templates/post'

interface TemplateProps {
  [key: string]: any
}

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    page: {
      title: string
      path: string
      flexContent: any
      acf: any
      seo: SeoProps
    }
  }
  prefix: string
}

interface ConvertTypes {
  [key: string]: any
}

const PreviewTemplate: React.FC<PageProps> = ({ location }) => {
  const urlParams = new URLSearchParams(location.search)
  const postId = urlParams.get('post_id')

  // @ts-ignore
  const { site } = useStaticQuery<GatsbyTypes.previewQueryQuery>(graphql`
    query previewQuery {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  const [revision, setRevision] = React.useState<any>(null)
  const [status, setStatus] = React.useState<'fetching' | 'error' | 'done'>(
    'fetching'
  )

  const templates: TemplateProps = {
    page: Page,
    post: Post,
  }

  const postTypeConverters = () => {
    const converters: ConvertTypes = {}

    Object.keys(templates)
      .filter((name: string) => !!templates[name].prefix)
      .forEach((name: string) => {
        converters[name] = templates[name].prefix
      })

    return converters
  }

  React.useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(
          `${
            site?.siteMetadata?.siteWpUrl
          }/wp-json/preview/v1/revision?post_id=${postId}&convert=${encodeURIComponent(
            JSON.stringify(postTypeConverters())
          )}`,
          { method: 'GET' }
        )

        const data = await response.json()

        setRevision(data)
        setStatus('done')
      } catch (error) {
        setStatus('error')
      }
    })()
  }, [])

  if (status === 'fetching') {
    return <Message content="Voorbeeld aan het laden ..." />
  }

  if (status === 'error') {
    return <Message content="Er ging iets fout" />
  }

  if (!revision || !revision.acf) {
    return (
      <Message
        content="Geen revisie(s) gevonden"
        subContent={`Ondersteunt berichttype "${revision.post_type}" revisies?`}
      />
    )
  }

  if (!templates[revision.post_type]) {
    return (
      <Message
        content={`Geen sjabloon gevonden voor berichttype "${revision.post_type}"`}
        subContent="Koppel het berichttype aan een sjabloon"
      />
    )
  }

  const templateData = {
    data: {
      page: {
        title: revision.post_title,
        path: location.pathname,
        seo: {
          title: revision.post_title,
        },
        ...revision.acf,
      },
    },
    location,
    prefix: `${revision.post_type}_Flexcontent`,
  } as PageTemplateProps

  const PageTemplate: React.FC<PageTemplateProps> =
    templates[revision.post_type].component

  return (
    <div className="preview">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PageTemplate {...templateData} />
    </div>
  )
}

interface MessageProps {
  content: string
  subContent?: string
}

const Message: React.FC<MessageProps> = ({ content, subContent = '' }) => (
  <div className="bg-[#f0f0f1] h-screen w-screen">
    <div className="bg-[#1d2327] w-full h-8" />
    <div className="py-5 d-flex justify-content-center">
      <div>
        <h1 className="font-primary text-2xl color-[#3c434a] text-center">
          {content}
        </h1>
        {subContent && (
          <h2 className="mt-3 font-primary text-sm italic color-[#646970] text-center">
            {subContent}
          </h2>
        )}
      </div>
    </div>
  </div>
)

export default PreviewTemplate
